/* Estilo para el fondo oscuro del popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Estilo para el contenido del popup */
  .popup_content {
    background: #BEE1D1;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    /*max-width: 400px;
    width: 100%;*/
    text-align: center;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    min-height: 500px;
    max-width: 800px;
    transform: translate(-50%, -50%);
    background: linear-gradient(4deg, #C2E0CF 0, #C2E0CF 70%, #8AC1A2 0, #8AC1A2);
  }
  .popup_content i{
    color: #DC5D58;
  }
  .popup_content  button{
    border: none;
    background: transparent;
    float: right;

  }
  .popup_content_box{
    border-radius: 8px;
  }
  .popup_content_box img{
    width: 100%;
  }

  .popup_content_box a{
    border: none;
    text-decoration: none;
  }
  .popup_content_box h2{
    color: #D4A1BB;
    text-shadow: 2px 3px #01534c;
    font-weight: 600;
    margin-top: 2rem;
  }
  .popup_text{
    margin-bottom: 1rem;
    color: #01534C;
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 60%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-size: 1.1rem;
    padding: 2rem;
  }
  .popup_logo{
    width: 155px;
    height: auto;
    bottom: 0;
    position: absolute;
    justify-content: center;
    display: flex;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .popup_logo img{
    width: 100%;
    height: auto;
  }




@media (max-width: 768px){
    .popup_content {
        width: 90%;
    }
}
@media (max-width: 480px){   
  .popup_content {
        width: 90%;
    }    
    .popup_text {     
      font-size: .9rem!important;

  }
  .popup_logo {
    width: 115px;
  }
}

