@import url('https://cdn-uicons.flaticon.com/2.5.1/uicons-solid-rounded/css/uicons-solid-rounded.css');
.capacitacion{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url('../assets/images/fondo-03.webp');
    background-size: 100% 100%;
}
.capacitacion-container{
    width: 100%;
    display: flex;
    padding-top: 3rem;
    justify-content: space-between;
}
.capacitacion-content:nth-child(1){
    width: 30%;
    max-width: 250px;
}
.capacitacion-content:nth-child(2){
    width: 70%;
}

.ambitos{
    /*
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    /* align-content: center; /
    height: 100%;*/
}

.capacitacion-box-button button{
    text-align: center;
    width: auto;
    min-width: 222px;
    align-content: center;
}
/*
.capacitacion-box-button:nth-child(1) button{
    padding: 10px 20px;
    color: #fff;
    background: #DC5D58;
    border-radius: 4px;
    border: none;
    margin: 16px 5px;
}
.capacitacion-box-button:nth-child(2) button{
    padding: 10px 20px;
    color: #fff;
    background: #8BC2A4;
    border-radius: 4px;
    border: none;
    margin: 16px 5px;
}
.capacitacion-box-button:nth-child(3) button{
    padding: 10px 20px;
    color: #1a1a1a;
    background: #ffffff;
    border-radius: 4px;
    border: none;
    margin: 16px 5px;
}
*/
.preguntas-content{
    width: 100%;
    display: flex;

    justify-content: space-between;
}
.criterios {
    width: 250px;
    min-width: 250px;
    display: flex;
}
.criterios i{
    color: #CCCCCC;
    font-size: 1.8rem;
}
.criterios i:hover{
    color: #454545;
    cursor: pointer;
}
.criterios label{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    width: 33%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.box-pregunta{
    padding: .8rem 1rem;
}
.box-pregunta label{
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    /*
        font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    */
}
.criterios input[type='radio']{
    transform: scale(2);
    cursor: pointer;
}
.box-pregunta label:nth-child(2){    
    font-size: 16px;
}
.preguntas-content-btn {
    justify-content: end;
    display: flex;
    margin: 2rem 2rem 0rem 0rem;
}
.preguntas-content-btn button:nth-child(1){
    padding: 7px 25px;
    /*background: #8BC2A4;*/
    background: #01534C;
    border: none;
    text-transform: uppercase;
    color: #fff;
    margin-right: 8px;
    border-radius: 4px;
}

.preguntas-content-btn button{
    padding: 7px 25px;
    /*background: #E89ABF;*/
    background: transparent;
    border: 2px solid #01534C;
    text-transform: uppercase;
    color: #01534C;
    border-radius: 4px;
}




/* Subir archivo */
.criterios input[type="file"] {
    display: none;
}

/* Estilo del botón personalizado */
.upload-button {
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
}

.upload-button i {
    margin-right: 5px;
}

/* Cambiar estilo en hover */
.upload-button:hover {
   color: #01534C;
}

.instrucciones{
   margin-bottom: 1.5rem;
   margin-left: 1rem;
}
.instrucciones h2{
    font-size: clamp(1.5rem, 4vw, 2rem);
    color: #01534C;
}

.capacitacion-box-button button {
    background: transparent;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 16px 5px;
  }
  
  .capacitacion-box-button button.active {
    background-color: #E89ABF;
    color: white;
    border-color: #E89ABF;
  }
  