.diagnostico{
    width: 100%;
    height: calc(100vh - 80px);
    background-image: url('../assets/images/fondo-05.webp');
    background-size: 100% 100%;
    align-content: center;
}
.diagnostico-container{
    width: 100%;
    height: calc(80vh - 1px);
    padding: 2rem 0rem;
    display: block;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 2rem;
    background: #fff;
    border-radius: 12px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 800px;
    /*background: linear-gradient(90deg, #7DC3A466 0, #7DC3A466 25%, #FFF 0, #FFF);*/
}
.diagnostico-box{
    width: 100%;
    padding: 2rem 0rem;
    display: flex;
    /* background: #fff; */
    border-radius: 12px;
}
.diagnostico-text {
    width: 25%;
}

.diagnostico-container h2 {
    font-size: 22px;
    font-weight: 600;
    /*color: #1a1a1a;*/
    color: #20534C;
}

.box-input-diagnostico {
    width: 100%;
    margin-bottom: 2rem;
}

.box-input-diagnostico-group{
    display: grid;
}
.box-input-diagnostico-group label{
    display: block;
    cursor: pointer;
    margin: .3rem 1rem;
}
.box-input-diagnostico-group input{
    width: auto;
}
.box-input-diagnostico i{
    color: red;
}

.box-input-diagnostico-group input[type='textarea']{
    min-height: 90px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    padding: 4px 10px;
    width: 80%;
}
.registro-inputs textarea {
    min-height: 70px;
    width: 80%;
    padding: .2rem .5rem;
}