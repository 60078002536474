.lista-usuarios{

    margin-top: 6rem;
    background: #FAFAFB;
    min-height: 100vh;
    height: auto;
}

.lista-usuarios button{
    padding: 7px 25px;
    background: #8BC2A4;
    border: none;
    text-transform: uppercase;
    color: #fff;
    margin-right: 8px;
}

.lista-usuarios button:hover{
    background: #20534C;
}

.lista-usuario-persona-fisica{
    padding: 4rem 0rem;
}



