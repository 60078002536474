
.dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    margin-top: 80px;
    background: #FAFAFB;
}
.sidebar{
    width: 25%;
    background: #7DC3A44D;
    max-width: 320px;
    padding: 2rem 0rem;
}
.dashboard-space{
    width: 100%;
    background: #FAFAFB;
    padding: 1rem;
}
.dashboard-content-capacitacion{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 0;
    
}
.dashboard-box-capacitacion{
    border-radius: 4px;
    display: grid;
    margin: 5px;
    padding: 1.5rem;
    width: calc(33% - 10px);
    background: #fff;
    border: 1px solid #dddddd;
    max-width: 400px;
}
.dashboard-box-capacitacion label:nth-child(1){
    color: #8C8C8C;
    font-size: 16px;
}
.dashboard-box-capacitacion label:nth-child(2){
    color: #1A1A1A;
    font-size: 2rem;
    font-weight: 500;
}
.dashboard-box-capacitacion label:nth-child(3){
    color: #8C8C8C;
    font-size: 14px;
}


.dashboard-admin-list-user{
    color: #8C8C8C;
}
.dashboard-admin-list-user h2{
    color: #1A1A1A;
    font-size: 1.3rem;
}
.dashboard-admin-list-user h2 label{
    color: #20534C;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 40px;
}
.dashboard-space a{
    text-decoration: none;
}
.lista-opciones-evidencias{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2rem;
}
.box-lista-opciones-evidencias{
    border-radius: 4px;
    display: grid;
    margin: 5px;
    padding: 1.5rem;
    width: calc(50% - 10px);
    background-color: #fff;
    border: 1px solid #dddddd;
    background-image: url('../assets/images/hacer-clic.webp');
    background-repeat: no-repeat;
    background-position: 100% 98%;
}

.box-lista-opciones-evidencias:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.box_bienvenida{
    width: 100%;
    min-height: 200px;
    padding: 2rem;
    background: rgb(32,83,76);
    background: linear-gradient(90deg, rgba(32,83,76,1) 35%, rgba(139,194,164,1) 100%);
    color: #fff;
    border-radius: 4px;
    justify-content: space-between;
    display: flex;
}
.box_bienvenida_img{
    display: flex;
    align-items: center;
}
.box_bienvenida img{
    max-width: 200px;
}

.box_bienvenida p{
    margin-bottom: 0rem;
}

.sidebar ul{
    margin-bottom: .5rem;
}
.sidebar ul li,
.sidebar ul li a{
    color: #1A1A1A;
    font-weight: 500;
    padding: .3rem 0rem;
    text-decoration: none;
}
.sidebar ul li i{
    margin-right: .3rem;
}
