
.login{
    width: 100%;
    background-image: url('../assets/images/fondo_verde_claro.png');
    height: auto;
    min-height: 100vh;
    background-size: cover;
    background-position: center;

}
.login-box-logo{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    display: none;
}
.form-content{
    position: relative;
    width: 100%;
    height: calc(100vh - 220px);
}
.form-content h2{
    text-align: center;
    color: #01534C;
}
.form-content form{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 4rem 2rem;
    border-radius: 5px;
    min-width: 500px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.input-group{
    margin: .5rem 0rem;
}

.input-group label {
    color: #01534C;
    font-weight: 700;
}

.form-content  input[type='email'],
.form-content  input[type='password']{
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #A3A3A3;
    border-radius: 4px;
}
.input-group button{
    width: 100%;
    padding: 10px 10px;
    border: none;
}

.form-content form p{
    text-align: center;
    color: #01534C;
    font-weight: 500;
}


@media (max-width: 480px){
    .form-content form {
        min-width: 425px;    
    }
}