.registro-completo{
    width: 100%;
    height: calc(100vh - 80px);
    background-image: url('../assets/images/registroCompleto.webp');
    background-size: 100% 100%;
    align-content: center;
}
.registro-completo-container{
    width: 100%;
    height: calc(80vh - 1px);
    padding: 2rem 0rem;
    display: block;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 2rem;
    background: #fff;
    border-radius: 12px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 800px;
}
.registro-completo-box{
    width: 100%;
    padding: 2rem 0rem;
    display: flex;
    background: #fff;
    border-radius: 12px;    
}

.registro-completo-container h2{
    font-size: 22px;
    font-weight: 600;
    color: #E89ABF;
}
.registro-completo-content{
    display: flex;
}
.box-input{
    width: 100%;
}
.box-input input{
    width: 100%;
}
.registro-text{
    width: 25%;
}
.registro-inputs{
    width: 70%;
}
.box-inputs{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0rem;
}
.box-inputs div{
    width: 100%;
}
.size-1 input[type="text"]{
    width: calc(100% - 16px);
    padding: 6px 8px;
    border: 1px solid #dbdbdb;
}

.size-2 input[type="text"],
.size-2 input[type="date"] {
    width: calc(100% - 16px);
    padding: 6px 8px;
    border: 1px solid #dbdbdb;
}
.size-3 input[type="text"],
.size-3 input[type="date"] {
    width: calc(100% - 16px);
    padding: 6px 8px;
    border: 1px solid #dbdbdb;
}
.size-3 select {
    width: calc(100% - 16px);
    padding: 6px 8px;
    border: 1px solid #dbdbdb;
    height: 38px;
}
.size-2 select {
    width: calc(100% - 16px);
    padding: 6px 8px;
    border: 1px solid #dbdbdb;
    height: 38px;
}
.box-button {
    justify-content: end;
    display: flex;
    margin-right: 16px;
}
.box-button button{
    padding: 7px 25px;
    background: #E89ABF;
    border: none;
    text-transform: uppercase;
    color: #fff;
}

.registro-completo-content label{
    font-weight: 600;
    text-transform: uppercase;
    margin: 1rem 2rem .5rem 0rem;
    align-items: center;
    display: flex;
}
.registro-completo-content input[type="radio"]{
    margin-left: 7px;
    transform: scale(1.3);
}
.box-input i,
.box-inputs i{
    color: red;
}

.alertaMensaje{
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    color: #fff;
    color: #721c24;
    background: #f8d7da;
    border-color: #f5c6cb;
}