@import url('https://cdn-uicons.flaticon.com/2.6.0/uicons-regular-straight/css/uicons-regular-straight.css');
.dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    margin-top: 80px;
    background: #FAFAFB;
}
.sidebar{
    width: 25%;
    max-width: 320px;
    background: #7DC3A44D;
}
.dashboard-space{
    width: 75%;
    background: #FAFAFB;
    padding: 1rem;
}
.dashboard-content-capacitacion{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 4rem 0;
    
}
.dashboard-box-capacitacion{
    border-radius: 4px;
    display: grid;
    margin: 5px;
    padding: 1.5rem;
    width: calc(33% - 10px);
    background: #fff;
    border: 1px solid #dddddd;
    max-width: 400px;
}
.dashboard-box-capacitacion:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
}
.dashboard-box-capacitacion.noEdit:hover{
    box-shadow: none;
    cursor: pointer;
}

.dashboard-box-capacitacion label:nth-child(1){
    color: #8C8C8C;
    font-size: 16px;
    cursor: pointer;
}
.dashboard-box-capacitacion label:nth-child(2){
    color: #1A1A1A;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;
}
.dashboard-box-capacitacion label:nth-child(3){
    color: #8C8C8C;
    font-size: 14px;
    cursor: pointer;
}

.documentacion-requerida{
    display: flex;
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #dddddd;
}
.content-documentacion-requerida{

    width: 50%;
    padding: 1rem;
}

.content-text-documentacion-requerida{
    width: 50%;
    padding: 1rem;
    align-content: center;
    background: #E4F0E8;
    align-items: center;
    justify-content: center;
    display: grid;
}
.content-text-documentacion-requerida h2{
    color: #1A1A1A;
    font-size: 1.5em;
    font-weight: 500;
}
.content-text-documentacion-requerida p{
    color: #1A1A1A;
}
.content-text-documentacion-requerida ul {
    padding-left: 1rem;
}
.content-text-documentacion-requerida ul li{
    color: #1A1A1A;
    list-style: disc;
}

.nota_documentacion h2{
    color: #1A1A1A;
    font-size: 1.2em;
}

.document-requerido{
    display: flex;
    align-items: center;
    background: #c6dfd15e;
    border-radius: 3px;
    color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    padding: .5rem;
    border: 1px solid #417e79;
}
.document-requerido label {
    font-size: 18px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.document-requerido i {
    display: flex;
    margin-right: 5px;
}

.document-requerido input[type="file"] {
    display: none;
}
.nota_documentacion {
    margin: .5rem 0rem 1rem 0rem;
}
.content-text-documentacion-requerida p{
    color: #8C8C8C;
}
.content-text-documentacion-requerida i{
    margin-right: 4px;
}
.file-status{    
    color: #01534c;
    display: block;
}
.status_archivo{
    display: flex;
    justify-content: space-between;
}
.status_archivo button{
    border-radius: 3px;
    color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    padding: .5rem;
    border: 1px solid #417e79;
}