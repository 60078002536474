.terminos_condiciones{
    padding: 6rem 0rem;
}
.terminos_condiciones h2{
    margin-bottom: 2rem;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: clamp(1.5rem, 4vw, 2rem);
}


/* Terminos Condiciones Participacion */
.TerminosCondicionesParticipacion{
    padding: 6rem 0rem;
}
.TerminosCondicionesParticipacion h2{
    margin-bottom: 2rem;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: clamp(1.5rem, 4vw, 2rem);
}