.footer{
    width: 100%;
    background: #02534C;
    padding-top: 6rem;
}

.hr-line{
    background: #6E9D99;
    width: 100%;
    height: 2px;
}
.footer_container{
    width: 100%;
    display: flex;
}
.footer_content:nth-child(1){
    width: 40%;
}
.footer_content:nth-child(2){
    width: 60%;
    display: flex;
    justify-content: space-around;
}
.copyright{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem 0rem;
}
.footer_logo_box{
    width: 100%;
    align-items: center;
}
.footer_logo_box img{
    width: 50%;
}
.footer_ods{
    width: 100%;
    align-items: center;
    display: flex;
}
.footer_ods img{
    width: calc(50% - 5px);
    max-width: 100%;    
    margin-right: 10px;
    margin-top: 1rem;
}
.list_opc ul{
    height: 100%;
    align-content: space-around;
    padding-left: 0rem;
}
.list_opc ul li,
.list_opc ul li a{
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin: 1.5rem 0rem;
    text-decoration: none;
}
.footer_redes_sociales{
    display: flex;
}
.box_link_social a img{
    width: 30px;
    height: 30px;
    margin: 0rem .7rem;
} 

/* Newsletter */
.footer_newsletter {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    width: 81%;
    max-width: 395px;    
    margin-top: .5rem;
}
.footer_newsletter input {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #000;
    font-weight: 500;
    margin: 5px 0;
    opacity: .5;
    padding: 7px 8px;
    width: 73%;
}
.footer_newsletter button {
    background: #fff0;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    margin: 5px 0;
    padding: 7px 8px;
    width: 25%;
}
.footer_newsletter button:hover{
    background: #D5A3BC;
}
.alertMessage_footer {
    margin-top: 1rem;
    color: #fff;
}



@media (max-width: 480px){
    .footer_content:nth-child(1) {
        width: 100%;
    }
    .footer_container {
        display: grid;
    }
    
    .footer_content:nth-child(2) {
        width: 100%;
        display: grid;
        justify-content: left;
    }
}
