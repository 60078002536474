.aviso_privacidad{
    padding: 6rem 0rem;
}
.aviso_privacidad h2{
    margin-bottom: 2rem;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: clamp(1.5rem, 4vw, 2rem);
}

.aviso_privacidad h3{
    font-size: clamp(1.5rem, 3.5vw, 1.5rem);
    font-weight: 600;
    margin-top: 1rem;
}
.aviso_privacidad p{
    font-size: clamp(1rem, 3vw, 1rem);
}
.aviso_privacidad ul li{
    font-size: clamp(1rem, 3vw, 1rem);
    list-style: disc;
}