*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}
@import url('https://cdn-uicons.flaticon.com/2.5.1/uicons-solid-straight/css/uicons-solid-straight.css');

nav{
    background: #01534C;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #417e79;
}
.enlace{
    position: absolute;
    padding: 20px 50px;
}
.logo{
    height: 40px;
}
nav ul{
    float: right;
    margin-right: 20px;
    margin-bottom: 0rem;
}
nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
}
nav ul li a{
    color: #fff;
    font-size: 18px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
}
/*
li a.active, li a:hover{
    background: #000090;
    transition: .5s;
}
*/
.checkbtn{
    font-size: 30px;
    color: #fff;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}
/*
section{
    background: url(fondo.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    height: calc(100vh - 80px);
}*/

.isSubmenu{
    display: none;    
}

.submenu:hover .isSubmenu{
    display: block;
    width: fit-content;
    background: #EC5B55;
    display: grid;
    position: absolute;
    z-index: 9;
    margin: -1px;
    padding: .5rem 0rem;
}
.isSubmenu {
    padding-left: 0rem;
    margin-right: 0px;

}
.isSubmenu li{
    line-height: normal;
    padding: 10px 10px;
}
.isSubmenu::after{
    border-bottom: 16px solid #EC5B55;
    border-left: 14px solid #33bca900;
    /* border-top: 16px solid #154cf4; */
    border-right: 14px solid #90909000;
    content: "";
    position: absolute;
    transform: translateY(-100%);
    left: 10px;
    
}
@media (max-width: 952px){
    .enlace{
        padding-left: 20px;
    }
    nav ul li a{
        font-size: 16px;
    }
}

@media (max-width: 858px){
    .checkbtn{
        display: block;
    }
    nav ul{
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #2c3e50;
        top: 80px;
        left: -100%;
        text-align: center;
        transition: all .5s;
    }
    nav ul li{
        display: block;
        margin: 50px 0;
        line-height: 30px;
    }
    nav ul li a{
        font-size: 20px;
    }
    li a:hover, li a.active{
        background: none;
        color: red;
    }
    #check:checked ~ ul{
        left:0;
    }
}

