.bases{
    font-family: 'CygreIt', sans-serif;
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-image: url('../assets/images/base.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.bases_container{
    width: 100%;
    display: flex;
    align-items: center;
}
.bases_content:nth-child(1){
    width: 45%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
}
.bases_content:nth-child(2){
    width: 55%;
    padding: 1.5rem;
    display: block;
    align-items: center;
}
.bases_content img{
    width: 100%;    
}
.bases_content h2{
    color: #01534C;
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    font-weight: 700;
}
.bases_content p{
    font-size: 20px;
    font-weight: 400;
    margin: 4rem 0rem;
}
.line{
    width: 100%;
    height: 10px;
    background-color: #7EC3A4;
}


/* Beneficios */
.beneficios{
    width: 100%;
    height: auto;
    min-height: 100vh;
}
.beneficios_container{
    width: 100%;
    display: flex;
    align-items: center;
    background: #01534C;            
}
.beneficios_content h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #7DC3A4;
    font-weight: 700;
    padding: 3rem 0rem 2rem 0rem;
}
.beneficios_content p{
    font-size: 20px;
    font-weight: 400;    
    color: #fff;
}

.beneficios_content:nth-child(1){
    width: 100%;
    padding: 2rem;
}
.beneficios_content:nth-child(2){
    width: 40%;
    background-image: url('../assets/images/beneficio.webp');
    height: 100vh;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
}
.beneficios_box{
    display: flex;
    margin: 1.5rem 0rem;
}
.beneficios_content img{
    width: 100%;
}
.num_box{
    min-width: 70px;
    height: 70px;
    font-size: clamp(2.5rem, 5vw, 3rem);
    /*background: #DDA2BC;*/
    background: #EC5B55;
    color: #01534C;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 700;
}
.beneficios_box p{
    align-items: center;
    display: flex;
    margin-bottom: 0rem;
    padding-left: 2rem;
}

/* Requisitos */

.requisitos{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url('../assets/images/requisitos.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4rem 0rem;
    align-content: center;
}
.requisitos_box_title h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #01534C;
    font-weight: 700;
}
.requisitos_box_title p{
    font-size: 21px;

}
.requisitos_box_img img{
    width: 100%;
    padding: 2.5rem 0rem;
}
.requisitos_box_disc{
    width: 65px;
    height: 65px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.requisitos_box {
    display: flex;
    margin-bottom: 1rem;
}
.requisitos_box_disc span{
    width: 25px;
    height: 25px;
    background: #7DC3A4;
    border-radius: 50%;
}
.requisitos_box p{
    width: 80%;
    align-items: center;
    display: flex;
    margin-bottom: 0rem;
    color: #01534C;
    font-size: 21px;
    font-weight: 400;
}
.categoria{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url('../assets/images/categoria.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4rem 0rem;
    align-content: center;
}
.categoria_container{
    display: grid;
}
.categoria_title{
    display: flex;
    justify-content: space-between;
}
.categoria_title h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #01534C;
    width: 30%;
    align-items: center;
    display: flex;
    align-content: center;
    font-weight: 700;
}
.categoria_title p{
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    width: 70%;
    padding: 2rem 0rem;
}
.categorias_content{
    display: flex;
    justify-content: space-between;
    padding: 4rem 0rem;
}
.categoria_content{
    display: flex;
}
.categorias_box{
    display: grid;
}
.categoria_content h3{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #EC5B55;
    font-weight: 700;
    margin-right: 1rem;
    margin-bottom: 0rem;
}
.categorias_box label{
    font-size: 22px;
    color: #01534C;
    font-weight: 600;
}
.categorias_box span{
    color: #fff;
    background: #fff;
    width: 100%;
    height: 3px;
}
.reconocimiento_content{
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem;
}
.reconocimiento_box:nth-child(1){
    width: 45%;
    background-color: #01534C;
    color: #fff;
    border-radius: 8px;
    padding: 1.5rem 2rem;
}
.reconocimiento_box:nth-child(2){
    width: 45%;
    background: #EC5B55;
    color: #fff;
    border-radius: 8px;
    padding: 1.5rem 2rem;
}
.reconocimiento_box h4{
    font-size: calc(2rem + .3vw);
}

.reconocimiento_box p{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
}
.reconocimiento_box ul{
    padding-left: 0rem;
    font-size: 20px;
}
.reconocimiento_box ul li{
    margin: .5rem 0rem;
    line-height: 28px;
}
.nota{
    color: #fff;
}

/* Comité evaluador */
.comite{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/requisitos.webp');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;    
    align-items: center;
    padding: 4rem 0rem;
}
.comite_box_img{
    width: 100%;
    background-color: #EC5B55;
    height: 200px;
    border-radius: 5px;
    margin: 2rem 0rem;
}
.comite_container h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    color: #01534C;
    font-weight: 700;
    text-transform: uppercase;

}
.comite_content{
    display: flex;
}
.comite_box{
    width: 25%;
    padding: 1rem;
}
.comite_box_text h3{
    font-size: clamp(2.5rem, 5vw, 3rem);
    color: #7DC3A4;
    text-align: center;
    font-weight: 600;
}
.comite_box_text label{
    font-size: clamp(1.2rem, 3vw, 1rem);
    color: #EC5B55;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: .5rem;
}


/* Aliados */
.aliados{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/categoria.webp');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;    
    align-items: center;
}
.aliados_title h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    color: #01534C;
    font-weight: 700;
    text-transform: uppercase;
}
.aliados_list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4rem 0rem;
}
.aliados_box{
    width: calc(25% - 10px); /* Calculamos el ancho restando el espacio deseado */
    margin: 5px; /* Agregamos un margen de 5px entre los elementos */
    box-sizing: border-box; /* Para incluir el padding y el borde en el ancho */
    margin-top: 10px;
    padding: 1rem;
}
.aliados_box_img{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.aliados_box img{
    width: 200px;
}

.evaluacion{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/base.webp');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;    
    align-items: center;
}
.evaluacion h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #01534C;
    font-weight: 700;
}

.evaluacion_content{
    display: flex;
}
.evaluacion_box {
    text-align: center;
    width: calc(25% - 10px);
    margin: 5px;
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 1rem;
    display: grid;
}
.evaluacion_box label{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    color: #909090;
}
.evaluacion_box h2{
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    color: #5C5C5C;
}
.evaluacion_box p{
    min-height: 240px;
    font-size: .9rem;
    height: auto;
}
.flechas_body{
    margin: 1rem 0rem;
}
.evaluacion_box img{
    width: 100%;
}

.inscripcion{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/fondo-12.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;    
    align-items: center;
    padding: 4rem 0rem;
}
.inscripcion h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #8BC2A4;
    font-weight: 700;

}
.inscripcion_box p{
    color: #fff;
    min-height: 100px;
}
.inscripcion_content{
    display: flex;  
    align-items: center;
}
.inscripcion_box {
    text-align: center;
    width: calc(20% - 10px);
    margin: 5px;
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 1rem;
}
.inscripcion_box label{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
}
.inscripcion_box h2{
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    color: #DC5E58;
}
.inscripcion_box img{
    width: 180px;
    margin-bottom: 2rem;
}
.inscripcion_box .flechas_body img{
    margin: 1rem;
    min-width: 200px;
}

.descargas{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/fondo_rojo.webp');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;    
    align-items: center;
}
.descargas img{
    width: 100%;
    min-width: 200px;
}

.descargas_content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4rem 0rem;
    justify-content: center;
}
.descargas_box{
    width: calc(25% - 10px);
    margin: 5px;
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 1rem;
}
.descargas_description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    background: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    min-width: 200px;
}
.descargas_description label,
.descargas_description label i{
    color: #1a1a1a;
    font-size: 1.1rem;
    font-weight: 500;
    align-items: center;
    display: flex;
}
.descargas_description a {
    text-decoration: none;
}
@media (max-width: 1260px){
    .categoria {
        height: auto;
        min-height: 100vh;
    }
    .categorias_content {
        padding: 2rem 0rem;
    }
}
@media (max-width: 952px){
    .bases{
        height: auto;
    }
    .bases_container{
        display: block;
    }
    .bases_content:nth-child(1),
    .bases_content:nth-child(2){
        width: 100%;
    }
    .beneficios{
        height: auto;
        padding: 4rem 0rem;
    }
    .beneficios_container{
        display: block;
    }
    .beneficios_content:nth-child(1),
    .beneficios_content:nth-child(2){
        width: 100%;
    }
    .beneficios_content:nth-child(2){
        height: 40vh;
    }
    .requisitos{
        height: auto;
    }
    .requisitos_box_disc{
        width: 50px;
        height: 70px;
    }
    .requisitos_box_disc span {
        width: 20px;
        height: 20px;
        background: #7DC3A4;
        border-radius: 50%;
    }
    .aliados{
        padding: 4rem 0rem;
        height: auto;
    }
    .aliados_box{
        width: 100%;
    }
    .categoria{
        height: auto;
    }
    .categoria_title{
        display: grid;
    }
    .categorias_content {
        display: block;
        padding: 1rem 0rem;
    }
    .categoria_title p{
        width: 100%;
    }
    .categoria_content {
        display: flex;
        justify-content: space-between;
        margin: 3rem 1rem;
    }
    .reconocimiento_content {
        display: block;
    }
    .reconocimiento_box:nth-child(1),
    .reconocimiento_box:nth-child(2){
        width: 100%;
    }

    .reconocimiento_box {
        width: 100%;
        margin-bottom: 1rem;
    }

    .comite {        
        height: auto;       
    }
    .comite_content{
        display: block;
    }
    .comite_box {
        width: 100%;
        padding: 1rem;
    }
    .comite_box_text {
        text-align: center;
    }
    .hero_content {
        width: 100%;        
    }

    .descargas {
        width: 100%;
        height: auto;
        height: auto;
        min-height: 100vh;
        background-size: cover;
        background-position: center;
    }

    .descargas_box {
        width: calc(50% - 10px);
        margin: 5px;
        box-sizing: border-box;
        margin-top: 2rem;
        padding: 1rem;
    }

    .inscripcion {
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-size: cover;
        background-position: center;

    }


    .inscripcion_content {
        display: grid;
        align-items: center;
    }

    .inscripcion_box {
        text-align: center;
        width: 100%;    
        box-sizing: border-box;
        margin-top: 1rem;
    }

    .evaluacion {
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-size: cover;
        background-position: center;
        padding: 4rem 0rem;
    }

    .evaluacion_content {
        display: grid;
    }
    .evaluacion_box {
        text-align: center;
        width: 100%;
        margin: 5px;
        box-sizing: border-box;
        margin-top: 2rem;
        padding: 1rem;
        display: grid;
    }


}

@media (max-width: 858px){

}
@media (max-width: 768px){
    .categoria_title {
        display: grid;
    }
}
@media (max-width: 480px){
    .descargas_box {
        width: 100%;
        margin: 5px;
        box-sizing: border-box;
        margin-top: 2rem;
        padding: 1rem;
    }

    .box_aliados {
        width: 100% !important;
        justify-content: center;
        display: flex;
    }

    
  
}