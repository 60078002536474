@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-SemiBold-BF63eeebf60aa18.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  *{
    font-family: 'Cygre', sans-serif;
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
  }
.hero{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/hero.webp');
    background-size: cover;
    background-repeat: no-repeat;
    /*margin-top: -80px;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.hero_container{
    width: 100%;
    height: 100vh;
    display: grid;
    align-items: center;
}
.hero_content{
    width: 50%;    
}
.hero_content img{
    width: 80%;
    margin-bottom: 3.5rem;    
}
.hero_content p{
    font-size: 2.7rem;
    color: #fff;
    font-weight: 500;  
    line-height: 50px;
}
.cta_hero a {
    min-width: 135px;
    font-weight: 600;    
}
.cta_hero a:nth-child(1){
    padding: .5rem 1rem;
    border: 2px solid #DC5D58;
    background: transparent;
    margin: 0rem 0.2rem;
    color: #fff;
    border-radius: 6px;
    font-weight: 600;
    text-decoration: none;
}
.cta_hero a:nth-child(1):hover{
    background:#DC5D58;
}
.cta_hero a:nth-child(2){
    padding: .5rem 1rem;
    border: 2px solid #8BC2A4;
    background: transparent;
    margin: 0rem 0.2rem;
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
}
.cta_hero a:nth-child(2):hover{
    background: #8BC2A4;
}
.cta_hero a:nth-child(3){
    padding: .5rem 1rem;
    border: 2px solid #ddd;
    background: transparent;
    margin: 0rem 0.2rem;
    color: #fff;
    border-radius: 6px;
    text-decoration: none;
}
.cta_hero a:nth-child(3):hover{
    background: #fff;
    color: #1a1a1a;
}
.cta_hero {
    margin: 1rem 0rem;
    display: flex;
    text-align: center;
}
@media (max-width: 952px){
   
    .hero_content:nth-child(1) {
        width: 100%;
    }
    .hero_content:nth-child(2){
        display: none;
    }
}

@media (max-width: 858px){
    
}


@media (max-width: 768px) {
    .hero {
        height: 80vh; /* Para pantallas más pequeñas como tablets y móviles */
    }
    .hero_content p {
        font-size: 2rem; /* Ajusta el tamaño del texto para móviles */
        line-height: 40px;
    }
    .cta_hero button {
        padding: 0.4rem 0.8rem; /* Botones más pequeños en móviles */
        min-width: 120px;
    }
}

@media (max-width: 480px) {
    .hero {
        height: 80vh; /* Para móviles más pequeños */
        background-size: cover; /* Asegura que cubra todo */
        background-position: center;
    }

    .hero_content p {
        font-size: 1.5rem; /* Texto más pequeño en pantallas pequeñas */
        line-height: 35px;
    }
    
    .hero_content img {
        width: 70%; /* Ajusta el tamaño del logo en móviles */
        margin-bottom: 2rem;
    }

    .cta_hero button {
        padding: 0.3rem 0.6rem; /* Botones más compactos en pantallas muy pequeñas */
        min-width: 100px;

    }
    .cta_hero a:nth-child(1) {
        padding: .4rem .5rem;
        font-size: 14px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .cta_hero a:nth-child(2) {
        padding: .4rem .5rem;
        font-size: 14px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .cta_hero a:nth-child(3) {
        padding: .4rem .5rem;
        font-size: 14px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .cta_hero a {
        min-width: 110px;
        font-weight: 600;
    }

    .categoria_title {
        display: grid!important;
    }
    .categoria_title p {
        width: 100%!important;
    }


}