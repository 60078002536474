.aliados-section{
    padding: 4rem 0rem;
    background-image: url('../assets/images/aliados.webp');
    background-repeat: repeat-y;
    background-size: contain;
}
.aliados-section-container{

}
.aliados-section-content{
    width: 100%;
    display: flex;
    padding: 3rem 0rem;
    margin: 1rem 0rem;
    flex-wrap: wrap;
}
.aliados-section-box1.img{
    width: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.aliados-section-box1.text{
    width: 50%;
    padding: 1rem;
}
.aliados-section-box1 img{
    width: 50%;
    min-width: 220px;
    max-width: 300px;
}
.aliados-section-box1 a{
    padding: .8rem 2rem;
    background: #7DC3A4;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
}
.aliados-section-box1 a:hover{
    background: #01534C;
}



@media (max-width: 952px){
   
}

@media (max-width: 768px){
    .aliados-section-content{
        display: flex;
        flex-direction: column;
        padding: 1rem 0rem;
        margin: 1rem 0rem;
    }
    .aliados-section-box1.img {
        width: 100%;
        order: 1;
    }
    .aliados-section-box1.text {
        width: 100%;
        order: 2;
    }
}
@media only screen and (max-width: 420px){
    .aliados-section-content{
        display: flex;
        flex-direction: column;
        padding: 1rem 0rem;
        margin: 1rem 0rem;
    }
    .aliados-section-box1.img {
        width: 100%;
        order: 1;
    }
    .aliados-section-box1.text {
        width: 100%;
        order: 2;
    }
}