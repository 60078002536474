@import url('https://cdn-uicons.flaticon.com/2.5.1/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.5.1/uicons-bold-rounded/css/uicons-bold-rounded.css');
.menucarga {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: background-color 0.3s ease;
    z-index: 1;
    height: 75px;
    align-items: center;
    display: flex;
    -webkit-box-shadow: 10px 4px 12px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 10px 4px 12px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 10px 4px 12px 0px rgba(0,0,0,0.4);
}
.menu_responsive_carga{
    display: none;
}
.logo_carga {
    align-content: center;
}
.menucarga.scrolled {
    background-color: #1A1A1A; 
}
.nav-menu-carga{
    display: flex;
    justify-content: space-between;
}
.menu_img_show_carga {
    background-image: url('../assets/images/logos/PAZ_Apex_Logotipo-04.png');
    width: 100px;
    height: 80px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.menucarga ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
}
.menucarga ul li {
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
}
.menucarga ul li .submenu_carga{
    display: none;
  }
.menucarga ul li:hover .submenu_carga{
    display: grid;
    padding: 5px 10px;
    border: 1px solid #f1f1f1;
}
.submenu_carga{
    background: #fff;
    min-width: auto;
    height: auto;
    position: absolute;
    -webkit-box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.17);
    box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.17);
}
.submenu_carga li{
    width: 100%;
}
.submenu_carga li a{
    margin: 5px 0px;
}
.menucarga.scrolled .menu_img_show_carga {
    background-image: url('../assets/images/logos/PAZ_Apex_Logotipo-05.png');
    width: 100px;
    height: 60px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.menucarga.scrolled ul li:hover .submenu_carga{
    background-color: #333;
    border: 1px solid #333;
}

.menucarga ul li a {
    font-size: 14px;
    color: #545454; 
    text-decoration: none;
    display: flex;
    font-weight: 600;
    align-items: center;
}
.menucarga ul li a i{
    display: flex;
}
.menucarga.scrolled ul li {
    color: #fff; 
    cursor: pointer;
}
.menucarga ul li:hover {
    color: #949494;
}
.menu_medio,
.menu_derecho{
    display: flex;
    align-items: center;
}
.footer_carga ul li a{
    text-decoration: none;
}

.menu_carga_activo{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #01534C;
    transition: background-color 0.3s ease;
    z-index: 1;

}
.submenu_list_carga{
    margin-top: 2rem;
}
.submenu_list_carga ul{
    margin-left: 0px;
    padding-left: 0px;
}
.submenu_list_carga ul li{
    border-bottom: 1px solid #fff;
    margin: 1rem 0rem;
}
.submenu_list_carga ul li a{
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}

ul.submenu_item{
    background: #1f3f5dd4;
    padding: 5px 0px;
}
ul.submenu_item li {
    color: #fff;
    border-bottom: none; 
    margin: 0.5rem 1rem!important;
}
.nav-menu-carga .menu_derecho li a{
    font-size: 14px;
    margin-right: 5px;
}
.regresarHome{
    width: 100%;
    background: #f5f5f5;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 35px;
}
.regresarHome a{
    color: #002648;
    text-decoration: none; 
}



.submenu-box {
    position: absolute;
    background: #fff;
    min-width: 260px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 4px;
    transform: translate3d(-140px, 0, 0px);
    cursor: pointer;
}
.submenu-box li:hover{
    background: #7DC3A466;
    color: #1A1A1A;
}
.submenu-box li{
    line-height: 50px!important;
    margin: 0!important;
    width: 100%;
    text-align: left;
}
.submenu-box li:hover label{
    color: #1A1A1A;
    cursor: pointer;
}
.submenu-box li i {
    margin: 0rem 1rem;
    cursor: pointer;
}
.submenu-box li:hover i {
    color: #1A1A1A;
    cursor: pointer;
}

@media (max-width: 468px){
}

@media (max-width: 768px) {
    .menu_img_show_carga {
        background-image: url('../assets/images/logos/PAZ_Apex_Logotipo-05.png');
        width: 45px;
        height: 45px;
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .menucarga{
        display: none;       
    }
    .menu_responsive_carga{
        display: block;
        /*background: #545454;*/
        background: #1A1A1A;
        height: 60px;
        align-items: center;
        display: flex;
    }
    
    .menu_responsive_carga_content{
        width: 100%;
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: 60px;
    }
    .servicio_transporte .slider-destino{
        margin-top: 0px;
    }
    .btn_menu_responsive_carga button{
        background: transparent;
        border: none;
        align-items: center;
        display: flex;
        color: #fff;
    }
    .btn_menu_responsive_carga button i{
        display: flex;
        font-size: 20px;
    }
    .text_servicio_transporte h2{ 
        font-size: 24px;
        letter-spacing: 0;
        line-height: 40px;
        font-weight: 400;
    }
    .text_servicio_transporte p{
        font-weight: 400;
        font-size: 15px;
    }
    .title_soluciones h2{
        font-size: 1.5rem;
    }
    .title_soluciones p{
        font-size: 1rem;
        font-weight: 400;
        padding: 0px 20px;
    }
   

}

@media (max-width: 952px){
    .menucarga ul li a{
        font-size: 12px;
        font-weight: 400;
    }
    .nav-menu-carga .menu_derecho li a{
        font-size: 12px;
        margin-right: 5px;
    }
    .menucarga ul li {        
        margin: 0 5px;
    }
    .menu_img_show_carga{
        width: 70px;
        height: 55px;
    }
   
}