
.registro{
    width: 100%;
    height: calc(100vh - 80px);
    background-image: url('../assets/images/fondo_verde_claro.png');
    background-size: 100% 100%;
}
.form-content{
    position: relative;
    width: 100%;
    height: calc(100vh - 220px);
}

.form-content form{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #7EC3A480;
    padding: 2rem;
    border-radius: 5px;
    min-width: 350px;
}
.input-group{
    margin: .5rem 0rem;
}
.form-content  input[type='email'],
.form-content  input[type='password']{
    width: 100%;
    padding: 8px 10px;
    border-radius: 3px;
    border: none;
}
.input-group button{
    width: 100%;
    padding: 8px 10px;
    border: none;
    background: #01534C;
    color: #fff;
    border-radius: 2px;
}

button:disabled {
    background-color: #417E79; /* Color para el botón deshabilitado */
    cursor: not-allowed; /* Cursor no permitido */
  }

.form-content  input[type='checkbox']{
    cursor: pointer;
    margin-top: .5rem;
    margin-bottom: 1rem;
}
