.aliados-container{
    height: auto;
    min-height: 100vh;
    align-content: center;
    background-image: url('../assets/images/base.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.aliados_content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4rem 0rem;
    justify-content: center;
}
.box_aliados{
    width: calc(25% - 10px);
    margin: 5px;
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 1rem;
}
.box_aliados img{
    width: 270px;
}



@media (max-width: 952px){
    .box_aliados{
        width: calc(33% - 10px);      
    }
}

@media (max-width: 768px){
    .aliados-container {
        height: 100%;     
    }
    .box_aliados{
        width: calc(50% - 10px);              
    }
    .box_aliados img {
        width: 100%;
    }
}
@media only screen and (max-width: 420px){
    .aliados-container {
        height: 100%;     
    }
    .box_aliados{
        width: calc(100% - 10px);      
    }
}