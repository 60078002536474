@import url('https://cdn-uicons.flaticon.com/2.5.1/uicons-bold-straight/css/uicons-bold-straight.css');
.premios{
    width: 100%;
    height: auto;
    min-height: 100vh; 
}

.premios_hero{
    width: 100%;
    height: calc(100vh - 80px);
    background-image: url('../assets/images/premios.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.premios_reconociemitno_container{
    display: flex;
}
.premios_reconociemitno_img{
    width: 40%;
}
.premios_reconociemitno_img img{
    width: 100%;
}
.premios_reconociemitno_faq{
    width: 60%;
    padding: 1rem;
    align-content: center;
    /*background-image: url('../assets/images/categoria.webp');*/
    background: #EADFDC;
    background-size: 100% 100%;
    align-content: center;
}
.premios_reconociemitno_faq h2{
   font-size: clamp(2.5rem, 5vw, 3rem);
   color: #01534C;
   text-transform: uppercase;
   font-weight: 600;
   margin-bottom: 2rem;
}

.preguntas_frecuentes_apex {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.faq-item {
    margin: 1rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.line-premios{
    width: 100%;
    height: 10px;
    background-color: #E89ABF;
}


.categoria_premios{
    width: 100%;
    height: 100vh;
    padding: 4rem 0rem;
    align-content: center;
}
.categoria_container{
    display: grid;
}
.categoria_title{
    display: flex;
    justify-content: space-between;
}
.categoria_title h2{
    font-size: clamp(2.5rem, 5vw, 3rem);
    text-transform: uppercase;
    color: #01534C;
    width: 30%;
    align-items: center;
    display: flex;
    align-content: center;
    font-weight: 700;
}
.categoria_title p{
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    width: 70%;
    padding: 2rem 0rem;
}
/*
.categorias_content{
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem;
}/
.categoria_premios_content{
    display: flex;
}
.categorias_box{
    display: grid;
}
.categoria_premios_content h3{
    font-size: 3.5rem;
    text-transform: uppercase;
    color: #E89ABF;
    font-weight: 700;
    margin-right: 1rem;
    margin-bottom: 0rem;
}
.categorias_box label{
    font-size: 22px;
    color: #01534C;
    font-weight: 700;
    justify-content: center;
    display: flex;
}
.categorias_box span{
    color: #fff;
    background: #fff;
    width: 100%;
    height: 3px;
    margin: .3rem 0rem;
}
.reconocimiento_premios_box:nth-child(1){
    width: calc(50% - 10px);
    background-color: #EC5B55;
    color: #fff;
    border-radius: 8px;
    padding: 1.5rem 2rem;
}
.reconocimiento_premios_box:nth-child(2){
    width: calc(50% - 10px);
    background-color: #7DC3A4;
    color: #fff;
    border-radius: 8px;
    padding: 1.5rem 2rem;
}
.reconocimiento_premios_box p{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
}
.nota_premios{
    color: #B3B3B4;
}

.reconocimiento_premios_content {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem;
}
*/

.box-question-box{
        display: flex;
        justify-content: space-between;
}
.box-question-box i{
    color: #DC5D58;
    cursor: pointer;
    font-size: 18px;
}
.box-question-box label{
    color: #01534C;
}
.answer{
    font-size: 17px;
    font-weight: 400;
    color: #01534c;
    margin-bottom: 2rem;
    margin-right: 2rem;
}

.hero_container_premios{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
}
.hero_content_premios {
    width: 100%;
    justify-content: end;
    display: flex;
    margin-top: -46%;
    padding-right: 352px;
}

.hero_content_premios h3 {
    font-size: 2.7rem;
    color: #fff;
    font-weight: 500;
    line-height: 50px;
}


@media (max-width: 952px){
    
    .premios_reconociemitno_container {
        display: block;
    }
    .premios_reconociemitno_img {
        width: 100%;
    }
    .premios_reconociemitno_faq {
        width: 100%;
    }
 
}

@media (max-width: 768px){
   
.premios_hero{
    width: 100%;
    height: calc(100vh - 80px);
    background-image: url('../assets/images/presea-hero.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: none;
}
}
@media only screen and (max-width: 420px){

}

