.correo{
    background: #E1F0E8;
}

.header_correo{
    display: flex;
    width: 100%;
    color: #7DC3A4;
    background:#01534C;
    justify-content: space-between;
    padding: 1rem 5%;
}
.header_correo p{
    width: 50%;
}
.header_correo img{
    max-width: 130px;
}

.body_content h2{
    background: #8CC2A4;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: .5rem 0rem;
}
.footer_correo{
    text-align: center;
    padding: .5rem 0rem;
    color: #888;
}
.body_content a{
    padding: .5rem;
    background: #8cc2a4;
    text-decoration: none;
    color: #fff;
    margin: .5rem 0rem;
}
