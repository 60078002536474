@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-Thin-BF63eeebf6d270b.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-Light-BF63eeebf809d3d.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-Regular-BF63eeebf4df0b6.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-Medium-BF63eeebf75cc00.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-SemiBold-BF63eeebf60aa18.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-Bold-BF63eeebf566546.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
 @font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-ExtraBold-BF63eeebf4aa137.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Cygre';
    src: url('../assets/tipografia/Cygre/Cygre-Black-BF63eeebf3942b2.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


/* CygreIt */

@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-BlackIt-BF63eeebf56b424.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-ExtraBoldIt-BF63eeebf54dc90.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-BoldIt-BF63eeebf93545d.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-SemiBoldIt-BF63eeebf552d0f.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-MediumIt-BF63eeebf65168e.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-RegularIt-BF63eeebf8170e4.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-LightIt-BF63eeebf5788b8.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-ThinIt-BF63eeebf4a58bf.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-Book-BF63eeebf814697.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CygreIt';
    src: url('../assets/tipografia/Cygre/Cygre-BookIt-BF63eeebf79e506.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}